<template>
  <div>
    <div class="ctl-table-container">
      <pil-catalog-filters
        @refresh-filters="refreshItemsList"
        @refresh-sort-params="refreshItemsListWithSort"
        :catalogTab="tab"
      />
      <div class="ctl-titles">
        <label class="checkbox-container">
          <input
            type="checkbox"
            hidden
            ref="selectAll"
            @input="updateCheckedItemsList"
          />
          <div class="checkbox-item"></div>
        </label>
        <div class="grid">
          <span
            class="ctl-titles-item"
            v-for="(t, idx) of titlesList"
            :key="idx"
            >{{ t.name }}</span
          >
        </div>
      </div>
      <div class="ctl-items-list">
        <div
          class="ctl-item"
          :class="{ reversed: idx % 2 }"
          v-for="(t, idx) of items"
          :key="idx"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              hidden
              :value="t"
              v-model="checkedItemsMod"
            />
            <div class="checkbox-item" :class="{ reversed: idx % 2 }"></div>
          </label>
          <div class="ctl-item-container grid" @click="showEditPopup(t)">
            <div class="ctl-review-container">
              <img alt="img" class="ctl-review-img" :src="t.img" />
            </div>
            <div class="ctl-item-param" :class="`status_${t.status}`">
              {{ t.status }}
            </div>
            <!-- ctl-item-start -->
            <!-- ctl-pre-line -->
            <!-- name -->
            <div class="ctl-item-param">{{ t.name }}</div>
            <!-- category -->
            <div class="ctl-item-param">
              {{ getCategories(t.categories) }}
            </div>
            <div class="ctl-item-param">{{ getSizeInMBs(t.size) }}</div>
            <div class="ctl-item-param">
              {{ getCorrectDate(t.created_at).slice(0, 19) }}
            </div>
            <div class="ctl-item-param">
              {{ getCorrectDate(t.updated_at).slice(0, 19) }}
            </div>
            <div class="ctl-item-param">
              {{ t.content_manager && t.content_manager.email }}
            </div>
            <!-- name -->
            <div class="ctl-item-param">{{ t.rating }}</div>
            <div class="ctl-item-param">
              {{ t.author && t.author.email }}
            </div>
            <div class="ctl-comments-text">
              <div v-for="(c, idx) of t.comment" :key="idx">
                <span class="ctl-comment-email">{{ c.user_email }}</span>
                <span class="ctl-comment-date">
                  ({{ getCorrectDate(c.date) }}):
                </span>
                <span
                  class="ctl-comment-msg"
                  :class="{
                    green: c.user_email !== t.author && t.author.emailor
                  }"
                  >{{ c.msg }}</span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="ctl-loader">
          <div
            v-infinity-scroll="{
              cb: fetchPathItemsList,
              tab
            }"
            id="scroll-trigger"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PilCatalogFilters from "@/components/catalog/Filters";
import catalogMixin from "@/mixins/catalogMixin";
import "@/assets/css/catalog.css";

export default {
  name: "tamplatesCatalog",
  props: ["tab"],
  mixins: [catalogMixin],
  components: {
    PilCatalogFilters
  },
  data() {
    return {
      // ? filter
      filters: {},
      sortParams: { created_at: "desc" },
      // ? titles
      titlesList: [
        { name: "preview" },
        { name: "status" },
        { name: "name" },
        { name: "categories" },
        { name: "size" },
        { name: "created at" },
        { name: "updated at" },
        { name: "content manager" },
        { name: "rating" },
        { name: "author" },
        { name: "comment" }
      ]
    };
  },
  computed: {
    ...mapGetters({
      items: "catalog/templates"
    })
  }
};
</script>

<style scoped>
.grid {
  display: grid;
  white-space: nowrap;
  grid-template-columns:
    minmax(75px, 75fr)
    minmax(120px, 120fr)
    minmax(150px, 150fr)
    minmax(250px, 250fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(150px, 150fr)
    minmax(200px, 200fr)
    minmax(400px, 400fr);
  grid-gap: 1vw;
  align-items: center;
  justify-items: center;
  width: 100%;
}
</style>
